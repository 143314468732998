@font-face {
  font-family: SpaceGrotesk;
  src: url('https://moon-capital-1257732598.cos.accelerate.myqcloud.com/SpaceGrotesk-VariableFont_wght.ttf');
}

@font-face {
  font-family: proxon;
  src: url('https://moon-capital-1257732598.cos.accelerate.myqcloud.com/PROXON.ttf');
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-2vh);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(2vh);
  }

  100% {
    transform: translatey(0px);
  }
}

.App,
#root,
html {
  overflow: hidden;
}

html * {
  font-family: SpaceGrotesk;
}

.MainContainer {
  background: url('https://moon-capital-1257732598.cos.accelerate.myqcloud.com/background.png');
  background-size: cover;
  overflow: hidden;
}

.header {
  height: 8vh;
  padding: 0px 8vh;
  text-align: center;
  z-index: 9999;
  position: absolute;
  top: 0;
  width: calc(100% - 16vh);
  min-width: 700px;
}

.header .logo {
  height: 4vh;
  float: left;
  margin: 2vh 0;
  position: absolute;
  left: 80px;
}

.Menu {
  display: inline-block;
  z-index: 9999;
}

.MenuItem {
  display: inline-block;
  color: #CED4DA;
  cursor: pointer;
  line-height: 6vh;
  margin: 1vh 2vh;
  font-size: 1.6vh;
  font-weight: bold;
  cursor: pointer;
  z-index: 9999
}

.MenuItem.selected,
.MenuItem:hover {
  color: #fff
}

.AboutPage {
  width: 100%;
  text-align: center;
  position: relative;
}

.AboutPageContext {
  color: #F8F9FA;
  font-family: SpaceGrotesk;
  display: inline-block;
  width: 68vw;
  font-size: max(1.2vw, 12px);
  line-height: 166%;
  position: absolute;
  bottom: calc(50% + 3vw);
  left: calc(50% - 34vw);
  letter-spacing: 1px;
}

.DownArrow {
  position: fixed;
  bottom: 100px;
  width: 20px;
  left: calc(50% - 10px);
  z-index: 9999;
  cursor: pointer;
}

.DownArrow.hidden {
  display: none;
}

.Footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  z-index: 9999;
}

.PageContainer {
  transition-timing-function: ease;
  position: absolute;
  top: 0;
  width: 100%;
  transition: 1s;
  z-index: 7777;
  width: fit-content;
}

.PageContainer.p4,
.PageContainer.p3,
.PageContainer.p2 {
  z-index: 9998
}

.PartnerPage,
.PartnerPage2,
.InvestmentPage,
.InterestPage {
  padding: 80px;
  box-sizing: border-box;
  text-align: left !important;
  position: relative;
}

.PartnerLeft {
  /* display: inline-block; */
  width: 500px;
  position: absolute;
  top: calc(50% - 175px);
  z-index: 9999;
}

.PartnerLeftUpper {
  color: white;
  font-family: SpaceGrotesk;
  font-size: 48px;
  line-height: 70px;
  letter-spacing: 2px;
  user-select: none;
}

.PartnerLeftLower {
  font-family: proxon;
  color: black;
  font-size: 48px;
  user-select: none;
  line-height: 60px;
  letter-spacing: 4px;
  /* -webkit-text-stroke: 2px white; */
  text-shadow: -2px 0 #ffffff, 0 2px #ffffff, 2px 0 #ffffff, 0 -2px #ffffff;
  margin-top: 10px;
  /* text-shadow: 0px 0px 3px #ffffff; */
}

.MoonPart {
  position: absolute;
  text-align: center;
  z-index: 1;
  transition: 1s;
  transition-timing-function: ease;
}

.MoonPart * {
  transition: 1s;
  transition-timing-function: ease;
}

.NameFull {
  z-index: 1;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  max-width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 200ms ease 0s;
  font-family: proxon;
  font-size: 15vh;
  color: white;
  letter-spacing: 4px;
  min-width: 1150px;
  user-select: none;
}

.NameFull.p0 {
  opacity: 1;
  transition: font-size 0s, opacity 300ms ease 500ms;
}

.NameFull .hidden,
.NameFront .hidden {
  opacity: 0;
  font-family: proxon;
  font-size: 15vh;
  color: white;
  letter-spacing: 4px;
}

.NameFront {
  z-index: 8889;
  top: 50%;
  left: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 200ms ease 0s;
  font-family: proxon;
  font-size: 15vh;
  color: white;
  letter-spacing: 4px;
  min-width: 1150px;
  user-select: none;
}

.NameFront.p0 {
  opacity: 1;
  transition: font-size 0s, opacity 200ms ease 500ms;
}

.ProjectDescriptionContainer {
  position: absolute;
  width: 376px;
  height: 252px;
  box-sizing: border-box;
  color: #fff;
  top: -51px;
  z-index: 889;
  /* overflow: hidden; */
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  background: rgba(0, 0, 0, 0.3);
  padding: 40px 48px;
  text-align: left;
  border-radius: 6px;
  box-shadow: #CED4DA 0px 1px 2px;
}

.ProjectDescriptionContainer.left {
  left: 0;
  right: unset;
}

.ProjectDescriptionContainer.right {
  right: 0;
  left: unset;
}

.ProjectDescriptionTitle {
  font-size: 24px;
  font-family: SpaceGrotesk;
  font-weight: 600;
  line-height: 31px;
}

.ProjectDescriptionContent {
  font-size: 16px;
  font-family: SpaceGrotesk;
  letter-spacing: 0.04em;
  line-height: 25px;
  font-weight: normal;
  margin-top: 10px;
}

/* .ProjectDescriptionBackground {
  position: absolute;
  width: 500px;
  height: 400px;
  top: -50px;
  left: -50px;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 888;
  filter: blur(30px);
} */

.PartnerPage2Text {
  font-family: SpaceGrotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 3.3vh;
  line-height: 5vh;
  position: absolute;
  top: calc(50vh - 28vh);
}

.PartnerPage2Text .normal {
  color: white;
}

.PartnerPage2Text .proxon {
  font-family: proxon;
  font-size: 3.3vh;
  letter-spacing: 0.1vh;
  color: rgba(0, 0, 0, 0.9);
  text-shadow: -2px 0 #ffffff, 0 2px #ffffff, 2px 0 #ffffff, 0 -2px #ffffff;
  /* text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5); */
}

.PartnerPage2Text text {
  font-family: proxon;
  font-size: 3.3vh;
  letter-spacing: 0.2vh;
}

.PartnerPage2Text svg {}

.MediaExchangeSelector {
  position: absolute;
  display: inline-block;
  background: linear-gradient(154.49deg, rgba(121, 117, 131, 0.2) 5.35%, rgba(54, 53, 103, 0.2) 83.85%), rgba(87, 103, 106, 0.3);
  backdrop-filter: blur(14px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 6px;
  padding: 0.5vh;
  top: calc(50vh - 12vh);
}

.MediaExchangeOption {
  display: inline-block;
  padding: 0.5vh 0;
  color: white;
  border-radius: 6px;
  width: 12vh;
  text-align: center;
  cursor: pointer;
  font-size: 1.8vh;
  font-family: SpaceGrotesk;
}

.MediaExchangeOption.selected,
.MediaExchangeOption:hover {
  background: #070D17;
}

.PartnerCarousel {
  position: absolute;
  top: calc(50vh + 0px);
  width: calc(100% - 160px);
}

.InvestmentCarousel {
  position: absolute;
  top: 26vh;
  width: calc(100% - 160px);
}

.carousel {
  width: calc(100%);
  height: 630px;
  /* height: 630px; */
  direction: rtl;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  box-sizing: border-box;
}


.carousel.active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.cWrapper {
  display: flex;
  justify-content: left;
  align-items: flex-end;
  cursor: grab;
  /* align-items: flex-start; */
  /* width: 100%; */
}

.carousel.active .cWrapper {
  cursor: grabbing;
}

.cWrapper.topmargin {
  margin-top: 30px;
  /* margin-left: -30px; */
}


.item {
  background: linear-gradient(154.49deg, rgba(121, 117, 131, 0.2) 5.35%, rgba(54, 53, 103, 0.2) 83.85%), rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(14px);
  /* -webkit-backdrop-filter: blur(14px); */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 6px;
  width: 302px;
  box-shadow: #CED4DA 0px 1px 2px;
  height: 215px;
}

.PartnerCarousel .item {
  width: 352px
}

.item:first-child {
  margin-right: 0px !important;
}

.item:last-child {
  margin-left: 0px !important;
}

.CarouselLogo {
  height: 30px;
  user-select: none;
}

.PartnerCarousel .CarouselLogo {
  height: 48px
}

.CarouselContent {
  font-size: 19px;
  /* user-select: none; */
  font-family: SpaceGrotesk;
  color: white;
  margin-top: 20px;
}

.PartnerCarousel .CarouselContent {
  font-size: 24px;
}

.CarouselContent a {
  color: white;
  text-decoration: none;
}

.CarouselContent a:hover {
  color: white;
  text-decoration: underline;

}


.CarouselItemContainer {
  padding: 32px 35px;
  width: 272px;
  box-sizing: border-box;
}

.PartnerCarousel .CarouselItemContainer {
  width: 352px;
  padding: 47px 45px;
}

.InvestmentPageTitle {
  position: absolute;
  font-weight: bold;
  font-size: 1.8vw;
  line-height: 123.5%;
  color: #fff;
  top: 14vh;
}

#ast1,
#ast0,
#ast2 {
  animation: float 6s ease-in-out infinite;
  transform: translatey(0px);
}

#ast22 {
  animation: float2 6s ease-in-out infinite;
  transform: translatey(0px);
}

.InterestPage {
  overflow-y: scroll;
  /* overflow-x: hidden; */
  padding: 0;
  margin-left: calc(50% - 100px);
  min-width: 680px;
  z-index: 9999;
}

.InterestPageTitle {
  font-family: SpaceGrotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 5vh;
  line-height: 123.5%;
  color: #fff
}

.InterestPageContent {
  font-family: SpaceGrotesk;
  font-style: normal;
  font-size: 2vh;
  line-height: 3.2vh;
  color: #fff;
  margin-top: 3vh;
}

.InterestPage::-webkit-scrollbar {
  width: 0px;
}

/* Track */

.InterestPage::-webkit-scrollbar-track {
  border: #f1f1f1 1px solid;
  width: 10px;
  border-radius: 6px;
}

/* Handle */

.InterestPage::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 6px;
}

/* Handle on hover */

.InterestPage::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.InterestSubPage {
  height: 100%;
  padding: 80px;
  scroll-snap-align: start;
}

.InterestPageIndicator {
  position: absolute;
  width: 10px;
  height: calc(100% - 2px);
  border-radius: 6px;
  border: 1px solid #CED4DA;
  top: 0;
  right: 0;
}

.InterestPageIndicatorItem {
  height: 33.333333%;
  background-color: #CED4DA;
  border-radius: 6px;
  transition: 1000ms;
}

.InterestPageIndicatorItem.p0 {
  transform: translatey(0);
}

.InterestPageIndicatorItem.p1 {
  transform: translatey(100%);
}

.InterestPageIndicatorItem.p2 {
  transform: translatey(200%);
}

.MoonImage {
  cursor: pointer;
  z-index: 888
}

.TwitterModule {
  position: fixed;
  bottom: 30px;
  right: 50px;
  z-index: 9999;
  padding: 10px;
  /* border-radius: 50px; */
  border: 1px solid #ADB5BD;
  height: 16px;
  border-radius: 50px;
}

.MediumModule {
  position: fixed;
  bottom: 30px;
  right: 110px;
  z-index: 9999;
  padding: 10px 10px 10px 10px;
  border-radius: 50px;
  border: 1px solid #ADB5BD;
  height: 16px;
}

.TwitterModule:hover, .MediumModule:hover {
  background: #222;
}

.TwitterModule img, .MediumModule img {
  width: 16px;
  height: 16px;
  margin-top: -10px;
}

.ContactModule {
  position: fixed;
  top: 2vh;
  right: 40px;
  z-index: 9999;
  color: white;
  font-family: SpaceGrotesk;
  cursor: pointer;
  /* width: 200px; */
  /* height: 30px; */
  background: linear-gradient(30deg, #2587B2, #131A1E);
  /* background: linear-gradient(#2587B2  ,#000000  ); */
  border-radius: 6px;
}

.ContactModule:hover {

  background: #2587B2;
}

.ContactModuleInner {
  background-color: #000;
  margin: 1px;
  padding: 8px 12px;
  border-radius: 6px;
  font-family: SpaceGrotesk;
  font-size: 1.6vh;
  letter-spacing: 1px;
}


.ContactModule img {
  height: 13px;
  margin-right: 14px;
}

.ContactInputContainer {
  padding: 20px 40px
}

.ContactIntroduction {
  font-size: 32px;
  font-family: SpaceGrotesk;
}

.ContactSubIntroduction {
  font-size: 16px;
  color: #888;
  margin-top: 6px;
  margin-left: 2px;
}

.ContactInputContainer input {
  display: block;
  height: 40px;
  width: 100%;
  outline: 0;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgb(244, 235, 255);
  border-bottom: 1px solid #aaa;
  margin-top: 20px;
  padding-left: 10px;
  font-size: 18px;
  background-color: unset;

}

.ContactInputContainer input:focus,
.ContactInputContainer input:hover {

  outline: 0;
}

.ContactInputContainer textarea {
  border: 1px solid #aaa;
  margin-top: 50px;
  font-size: 18px;
  outline: 0;
  border-radius: 0;
  background-color: unset;
  resize: none;
  padding: 10px;
  display: block;
  width: calc(100% - 8px);
  margin-right: 10px;
  height: 200px;
  border-radius: 5px;
}

.ContactButtonContainer {
  margin-top: 40px;
  text-align: right;
  padding: 0;
  margin-right: -12px;
}

.ContactButtonContainer button {
  margin: 0;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  background: linear-gradient(-122deg, rgb(216, 184, 255), rgb(158, 250, 255));
  border-radius: 3px;
  border: 0;
  color: #222
}